import _ from "lodash";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import {
  qalBoxSearchMediaV2Hook,
  qalBoxDeleteMediaHook,
  qalBoxSyncElasticHook,
  qalboxUseDebounce,
  qalboxDuplicateManifest,
} from "../../../hooks";
import {
  getDeletableMedia,
  getMediaIdsToDelete,
  getMediaItemsColumnsV2,
  getSearchMediaDisplayData,
} from "../helper";
import { DEFAULT_SUPPORTED_LANGUAGE, DEFAULT_MEDIA_TYPE_SEARCH_FILTER, DEFAULT_PREMIUM_SEARCH_FILTER } from "../Constants";
import QalboxTable from "../../../widgets/qalbox-table";
import { confirmAlert } from "react-confirm-alert";
import { ScaleLoader } from "react-spinners";
import { MPInput } from "../../../widgets/common";
import { QalboxDisplayCategoriesDropdown } from "../displayCategories/helperComponents/QalboxDisplayCategoriesDropdown";
import { QalboxMediaSeriesDropdown } from "../displayCategories/helperComponents/QalboxMediaSeriesDropdown";
import { QalboxRedisClearCacheButton } from "./helperComponents/qalboxRedisCacheClearButton";
import QalboxSearchFilter from "./helperComponents/qalboxSearchFilter";

const MetadataModal = lazy(() => import('./modalScreens/metadataModal'));
const AppModal = lazy(() => import("../../../widgets/modal"));
const ImagesModal = lazy(() => import("./modalScreens/imagesModal"));
const MediaPrimaryDetailsModal = lazy(() => import("./modalScreens/mediaPrimaryDetailsModal"));
const FeaturedDataModal = lazy(() => import("./modalScreens/featuredDataModal"));
const QalboxViewAllModal = lazy(() => import("./modalScreens/mediaQalboxViewAll"));
const QalboxProdSyncModal = lazy(() => import("./modalScreens/prodSyncModal"));
const AdUnitModal = lazy(() => import("./modalScreens/adUnitsModal"))

const search_config = {
  language_code: "en",
  queryText: "",
  paginate: {
    page: 1,
    size: 200,
  },
};

const SearchMediaV2 = () => {
  const [row, setRow] = useState({});
  const [language, setLanguage] = useState(DEFAULT_SUPPORTED_LANGUAGE);
  const [title, setTitle] = useState("");
  const [seriesIds, setSeriesIds] = useState([]);
  const [filterMediaType, setFilterMediaType] = useState([]);
  const [filterMediaPremium, setFilterMediaPremium] = useState([]);
  const [displayCategoryTags, setDisplayCategoryTags] = useState([]);
  const [category, setCategory] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const debouncedSearchValue = qalboxUseDebounce(title, 1000);

  const [responseData, setOperationData] = qalBoxSearchMediaV2Hook();
  const [deleteResponseData, setDeleteOperationData] = qalBoxDeleteMediaHook();
  const [syncResponse, setSyncOperationData] = qalBoxSyncElasticHook();
  const [dupicateManifestData, duplicateManifestFile] =
    qalboxDuplicateManifest();

  const [showMediaPrimaryDetailsModal, setShowMediaPrimaryDetailsModal] =
    useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [showFeaturedDataModal, setShowFeaturedDataModal] = useState(false);
  const [showAVODModal, setShowAVODModal] = useState(false);
  const [showViewAllModal, setShowViewAllModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleRowClickView = (row, columnID) => {
    setRow(row);
    setEditMode(true);
    console.log(row);
    console.log(columnID);
    switch (columnID) {
      case 1:
        setShowMediaPrimaryDetailsModal(true);
        break;
      case 2:
        setShowImagesModal(true);
        break;
      case 3:
        setShowMetadataModal(true);
        break;
      case 4:
        if (row.featured) {
          setShowFeaturedDataModal(true);
        } else {
          onFeaturedPressed();
        }
      case 5:
        setShowAVODModal(true);
        break;
      default:
        break;
    }
  };

  const viewAllDetails = (row) => {
    setRow(row);
    setShowViewAllModal(true);
  };

  const handleSeriesDetailsUpdateInModal = (isError) => {
    if (isError) {
      toast.error("Error occured while fetching series data", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.success(`Series data updated to Episodes.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const onOperationComplete = (operationData) => {
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const onDeleteComplete = (operationData, resetSelectedRows) => {
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.success(`Selected media items deleted successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      resetSelectedRows();
    }
  };

  const handleEncodingCompleted = () => {
    toast.success(`Selected media items Encoded successfully.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const hanldePurgeCDN = (isError) => {
    if (!isError) {
      toast.success(`CDN cache cleared successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error("Error occurred when clearing cache", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const onSyncComplete = (operationData, resetSelectedRows) => {
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.success(
        `Selected media items synced to elastic search successfully.`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      resetSelectedRows();
    }

    requestData();
  };

  const requestData = () => {
    setOperationData({
      searchConfig: {
        ...search_config,
        language_code: language,
        queryText: debouncedSearchValue,
        seriesIds: seriesIds?.map((x) => x.value),
        tags: displayCategoryTags,
        filterMediaType: filterMediaType.searchFilter,
        filterMediaPremium: filterMediaPremium.searchFilter
      },
      onOperationComplete,
    });
  };

  useEffect(() => {
    requestData();
  }, [seriesIds, displayCategoryTags, debouncedSearchValue, filterMediaType, filterMediaPremium]);

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <AppModal
              show={true}
              showBody={true}
              headingText={`Are you sure want to delete the following ${_.size(
                selectedFlatRows
              )} media items ?`}
              modalBody={getDeletableMedia(selectedFlatRows)}
              modalBodyClassName={"precalcs-diff-merge-view"}
              closeButtonText={"No"}
              okButtonText={"Yes, Delete"}
              handleClose={onClose}
              handleOk={() => {
                onClose();
                console.log(getMediaIdsToDelete(selectedFlatRows));
                setDeleteOperationData({
                  deleteConfig: {
                    media_ids: getMediaIdsToDelete(selectedFlatRows),
                    language_code: language,
                  },
                  onOperationComplete: (response) =>
                    onDeleteComplete(response, resetSelectedRows),
                });
              }}
            />
          </Suspense>
        );
      },
    });
  };

  const onSyncElasticSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <AppModal
              show={true}
              showBody={true}
              headingText={`Are you sure want to sync the following ${_.size(
                selectedFlatRows
              )} media items to Elastic Search ?`}
              modalBody={getDeletableMedia(selectedFlatRows)}
              modalBodyClassName={"precalcs-diff-merge-view"}
              closeButtonText={"No"}
              okButtonText={"Yes, Sync"}
              handleClose={onClose}
              handleOk={() => {
                onClose();
                console.log(getMediaIdsToDelete(selectedFlatRows));
                const mediaIds = getMediaIdsToDelete(selectedFlatRows);
                const filteredMedia = _.get(responseData, "data", []).filter(
                  (media) => mediaIds.includes(media.id)
                );
                setSyncOperationData({
                  items: filteredMedia,
                  onOperationComplete: (response) =>
                    onSyncComplete(response, resetSelectedRows),
                });
              }}
            />
          </Suspense>
        );
      },
    });
  };

  const onProdSyncCompleted = (resetSelectedRows) => {
    toast.success(
      `Production sync completed successfully.`,
      {
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    resetSelectedRows();
  }

  const handleProdSync = (selectedFlatRows, resetSelectedRows) => {
    const mediaIds = getMediaIdsToDelete(selectedFlatRows);
    const mediaToSync =  _.filter(_.get(responseData, "data", []), item => _.includes(mediaIds, item.uuid));

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <AppModal
              show={true}
              showBody={true}
              headingText={`Prod Sync`}
              modalBody={
                <QalboxProdSyncModal
                  mediaList={mediaToSync}
                  onProdSyncCompleted={() => {
                    onProdSyncCompleted(resetSelectedRows);
                    onClose();
                  }}
                />
              }
              modalBodyClassName={""}
              closeButtonText={""}
              okButtonText={""}
              handleClose={onClose}
            />
          </Suspense>
        );
      },
    });
  }

  const onManifestDuplicate = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <AppModal
              show={true}
              showBody={true}
              headingText={`Are you sure want to duplicate manifest for the following ${_.size(
                selectedFlatRows
              )} media items?`}
              modalBody={getDeletableMedia(selectedFlatRows)}
              modalBodyClassName={"precalcs-diff-merge-view"}
              closeButtonText={"No"}
              okButtonText={"Yes"}
              handleClose={onClose}
              handleOk={() => {
                onClose();
                const mediaType = _.get(selectedFlatRows[0], "original.type");
                const sourceUrlArr =
                  mediaType === "series"
                    ? _.get(
                        selectedFlatRows[0],
                        "original.previews.props.previews"
                      )
                    : _.get(selectedFlatRows[0], "original.urls.props.urls");

                if (!sourceUrlArr || sourceUrlArr.length <= 0) {
                  return;
                }

                const sourceUrl =
                  sourceUrlArr[0] && sourceUrlArr[0].src
                    ? sourceUrlArr[0].src
                    : "";
                const urlArr = sourceUrl.split("https://cdn.media.qalbox.com/");

                if (!urlArr || urlArr.length <= 1) {
                  return;
                }
                duplicateManifestFile({
                  media: {
                    folder_path: urlArr[1],
                  },
                  onDuplicateManifestComplete: (response) => {
                    onDuplicateManifestComplete(response, resetSelectedRows);
                  },
                });
              }}
            />
          </Suspense>
        );
      },
    });
  };

  const onDuplicateManifestComplete = (response, resetSelectedRows) => {
    if (response.isError) {
      toast.error(response.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.success(`Manifest file duplicated successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      resetSelectedRows();
    }

    resetSelectedRows();
  };

  const onNextPressed = (method1, method2) => {
    method2(false);
    method1(true);
  };

  const onCreatePressed = () => {
    setEditMode(false);
    setShowMediaPrimaryDetailsModal(true);
  };

  const onFeaturedPressed = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <AppModal
              show={true}
              showBody={true}
              headingText={`Featured Media`}
              modalClassName="app-modal-confirm"
              modalBody={"Do you want to make this a Featured Media"}
              modalBodyClassName={""}
              closeButtonText={"No"}
              okButtonText={"Yes"}
              handleClose={() => {
                if (!editMode) {
                  setShowMetadataModal(true);
                }

                onClose();
              }}
              handleOk={() => {
                setShowFeaturedDataModal(true);
                onClose();
              }}
            />
          </Suspense>
        );
      },
    });
  };

  const onStepCompleted = (response, rowObject) => {
    if (response.isError) {
      toast.error(response.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      return;
    }

    setRow(rowObject);

    toast.success(`Primary Details Updated successfully.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    if (!editMode) {
      const temp_row = {
        ...rowObject,
        id: _.get(response, "data.created[0].id", null),
      };

      setRow(temp_row);

      onNextPressed(setShowImagesModal, setShowMediaPrimaryDetailsModal);
    } else {
      setShowMediaPrimaryDetailsModal(false);
    }

    requestData();
  };

  const onStep2Completed = (response, rowObject) => {
    if (response.isError) {
      toast.error(response.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      return;
    }

    setRow(rowObject);

    toast.success(`Image Details Updated successfully.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    if (!editMode) {
      setShowImagesModal(false);
      onFeaturedPressed();
    } else {
      setShowImagesModal(false);
    }

    requestData();
  };

  const onMetadataModalCompleted = (response, rowObject) => {
    if (response.isError) {
      toast.error(response.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      return;
    }

    setRow(rowObject);

    toast.success(`Metadata Details Updated successfully.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    if (!editMode) {
      setShowMetadataModal(false);
    } else {
      setShowMetadataModal(false);
    }

    requestData();
  };

  const onAdUnitModalCompleted = (response, rowObject) => {
    if (response.isError) {
      toast.error(response.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      return;
    }

    setRow(rowObject);

    toast.success(`Ad Units Details Updated successfully.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    if (!editMode) {
      setShowAVODModal(false);
    } else {
      setShowAVODModal(false);
    }

    requestData();
  };

  const onFeaturedModalCompleted = (response, rowObject) => {
    if (response.isError) {
      toast.error(response.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      return;
    }

    setRow(rowObject);

    toast.success(`Featured Details Updated successfully.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    if (!editMode) {
      onNextPressed(setShowMetadataModal, setShowFeaturedDataModal);
    } else {
      setShowFeaturedDataModal(false);
    }

    requestData();
  };

  const handleDisplayCategorySelect = (name, array, displayCategories) => {
    const value = array.map((x) => x.value);
    const tags = displayCategories.flatMap((item1) =>
      value.some((item2) => item2 === item1.id) ? item1.tags.split(",") : []
    );
    const categories = displayCategories.flatMap((item1) =>
      value.some((item2) => item2 === item1.id) ? item1.id : []
    );
    setCategory(categories);
    setDisplayCategoryTags(tags);
    setPageIndex(0);
  };

  const handleMediaTypeSelect = (name, option) => {
    setFilterMediaType((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleMediaPremiumSelect = (name, option) => {
    setFilterMediaPremium((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleMediaSeriesSelect = (name, array) => {
    setSeriesIds(array);
    setPageIndex(0);
  };

  return (
    <LoadingOverlay
      active={_.get(deleteResponseData, "isDeleting", false)}
      spinner={<ScaleLoader />}
      text="Loading...."
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Media"}</h3>
          </Col>
          {process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE && (
            <Col md={3} style={{ paddingTop: "20px" }}>
              <button
                className="qalbox-create-button"
                onClick={() => {
                  onCreatePressed();
                  setRow(null);
                }}
              >
                Create
              </button>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label
                style={{
                  marginRight: "5px",
                }}
              >
                {"Title : "}
              </label>
              <MPInput
                value={title}
                placeHolder={"Title"}
                onChangeValue={(e) => {
                  setTitle(e);
                  setPageIndex(0);
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <QalboxMediaSeriesDropdown
              mediaSeries={seriesIds}
              handleMultiSelect={handleMediaSeriesSelect}
            />
          </Col>
          <Col md={4}>
            <QalboxDisplayCategoriesDropdown
              displayCategories={category}
              handleMultiSelect={handleDisplayCategorySelect}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <QalboxSearchFilter
              labelName={"Media Type : "}
              filterList={DEFAULT_MEDIA_TYPE_SEARCH_FILTER}
              selectedSearchFilter={filterMediaType}
              handleSearchFilterSelect={handleMediaTypeSelect}
            />
          </Col>
          <Col md={4}>
            <QalboxSearchFilter
              labelName={"Free / Premium : "}
              filterList={DEFAULT_PREMIUM_SEARCH_FILTER}
              selectedSearchFilter={filterMediaPremium}
              handleSearchFilterSelect={handleMediaPremiumSelect}
            />
          </Col>
        </Row>
        <br></br>
        <Row style={{ marginBottom: '10px' }}>
          <Col md={12}>
            <QalboxRedisClearCacheButton />
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getSearchMediaDisplayData(
              _.get(responseData, "data", []),
              handleRowClickView,
              viewAllDetails
            ),
            columns: getMediaItemsColumnsV2(),
            handleDelete: onDeleteSelected,
            handleSync: onSyncElasticSelected,
            handleManifest: onManifestDuplicate,
            handleProdSync: process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE
              ? handleProdSync
              : null,
            isLoading: _.get(responseData, "isLoading", true),
            tableError: _.get(responseData, "isError", true),
            isGlobalSearchOn: false,
            parentPageIndex: pageIndex,
            setPageIndex: setPageIndex,
          }}
        />
      </BasicLayout>
      <Suspense fallback={<div>Loading...</div>}>
        <AppModal
          show={showMediaPrimaryDetailsModal}
          showBody={true}
          headingText={`Create Media | Media Primary Details ${
            row && row.title ? " | " + row.title : ""
          }`}
          modalBody={
            <MediaPrimaryDetailsModal
              data={row}
              onStepCompleted={onStepCompleted}
              handleEncodingCompleted={handleEncodingCompleted}
              hanldePurgeCDN={hanldePurgeCDN}
              handleSeriesDetailsUpdateInModal={
                handleSeriesDetailsUpdateInModal
              }
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => setShowMediaPrimaryDetailsModal(false)}
        />
        <AppModal
          show={showImagesModal}
          showBody={true}
          headingText={`Create Media | Images ${
            row && row.title ? " | " + row.title : ""
          }`}
          modalBody={
            <ImagesModal data={row} onStepCompleted={onStep2Completed} />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => setShowImagesModal(false)}
        />
        <AppModal
          show={showMetadataModal}
          showBody={true}
          headingText={`Create Media | Metadata ${
            row && row.title ? " | " + row.title : ""
          }`}
          modalBody={
            <MetadataModal
              data={row}
              onStepCompleted={onMetadataModalCompleted}
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => setShowMetadataModal(false)}
        />
        <AppModal
          show={showFeaturedDataModal}
          showBody={true}
          headingText={`Create Media | Featured Data ${
            row && row.title ? " | " + row.title : ""
          }`}
          modalBody={
            <FeaturedDataModal
              data={row}
              onStepCompleted={onFeaturedModalCompleted}
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => setShowFeaturedDataModal(false)}
        />
        <AppModal
          show={showViewAllModal}
          showBody={true}
          headingText={`All media data for ${
            row && row.title ? " | " + row.title : ""
          }`}
          modalBody={<QalboxViewAllModal data={row} />}
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => setShowViewAllModal(false)}
        />
        <AppModal
          show={showAVODModal}
          showBody={true}
          headingText={`Create Media | Ad Units ${
            row && row.title ? " | " + row.title : ""
          }`}
          modalBody={
            <AdUnitModal
              data={row}
              onStepCompleted={onAdUnitModalCompleted}
            />
          }
          modalBodyClassName={""}
          closeButtonText={""}
          okButtonText={""}
          handleClose={() => setShowAVODModal(false)}
        />
      </Suspense>
    </LoadingOverlay>
  );
};

export default SearchMediaV2;
