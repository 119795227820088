import { useState } from 'react';
import axiosService from '../../../../../service_v2/common/axios-service';

export const useRedisCacheClear = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const cacheClear = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.post(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-media-service/v1/clear/redis`
      );

      if (response.status === 200) {
        setSuccess(true);
      } else {
        setError("Failed to clear cache");
      }
    } catch (error) {
      setError("An error occurred while clearing the cache");
    } finally {
      setLoading(false);
    }
  };

  return [ cacheClear, loading, error, success ];
};